/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-11-14 16:21:40
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2019-11-18 18:32:32
 * @Description: 数据统计
 * @Route: /data-statistics
 */


<template>
  <div class="data-statistics">
    <span class="label">整体趋势</span>
    <el-date-picker
      v-model="time"
      size="small"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期" />

    <el-button type="warning"
               class="search_btn"
               size="mini">
      查询
    </el-button>


    <div class="show_card">
      <el-card class="box-card">
        <div class="card_title">
          累计观看人次
        </div>
        <div class="card_content">
          123123123
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card_title">
          累计观看人数
        </div>
        <div class="card_content">
          123123123
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card_title">
          平均每日看人次
        </div>
        <div class="card_content">
          123123123
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card_title">
          平均每日看人数
        </div>
        <div class="card_content">
          123123123
        </div>
      </el-card>
    </div>

    <div id="charts" />
  </div>
</template>

<script>
import charts from '@/utils/charts';

export default {

  data() {
    return {
      time: [],
    };
  },
  mounted() {
    charts.CreateLine.create({
      id: 'charts',
      typeLabel: ['人数', '人次'],
      data: [[55, 99, 33, 88], [44, 33, 22, 66]],
      label: ['2019-8-1', '2019-9-1', '2019-10-1', ' 2019-11-1'],
    })
      .build();
  },

};
</script>

<style lang="scss" scoped>

.data-statistics {
  padding: 30px;
}

.label {
  font-size: 14px;
  margin-right: 20px;
}

.search_btn {
  margin-left: 10px;
}

.show_card {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.box-card {
  min-width: 250px;
  .card_title {
    font-size: 22px;
    color: #999;
  }

  .card_content {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }
}

#charts {
  min-height: 500px;
}
</style>
