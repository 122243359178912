import Echarts from 'echarts';

/**
 * options
 *      id : 图表的dom ID
 *      typeLabel ： 线类型名称
 *      label ： x轴节点的名称 ,
 *      data : 数据 格式为二维数组，每项需要跟typeLabel一一对应
 */
class CreateLine {
  static create(options) {
    return new CreateLine(options);
  }

  constructor(options) {
    const { id, label, data } = options;
    const { typeLabel } = options;

    this.myCharts = Echarts.init(document.getElementById(id));
    this.id = id;
    // img_name = img_name || Math.random().toString(36).substr(2);
    const series = [];
    typeLabel.forEach((item, index) => {
      const seriesItem = {
        name: item,
        type: 'line',
        data: data[index],
      };
      series.push(seriesItem);
    });

    const chartsOptions = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: typeLabel,
        bottom: 10,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: label,
      },
      yAxis: {
        type: 'value',
      },
      series,
    };
    this.option = chartsOptions;
  }

  build() {
    const { option, myCharts } = this;
    this.option = null;
    this.myCharts = null;
    myCharts.setOption(option);
    return myCharts;
  }
}

export default {
  CreateLine,
};
